import React from 'react';

export default function Header() {
    return (
        <div className="container-fluid bg-dark py-2 d-none d-md-flex">
            <div className="container">
                <div className="d-flex justify-content-between topbar" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="top-info">
                        {/* <small className="me-2 text-white-50"><a href="www.ikartsolution.com"><i className="fas fa-map-marker-alt me-2 text-secondary"></i></a>Gujarat, Ahmedabad, India</small> */}
                        <small className="me-2 text-white-50"><a href="www.ikartsolution.com"><i className="fas fa-envelope me-2 text-secondary"></i></a>sales@iotfied.com</small>
                    </div>
                    <div id="note" className="text-secondary d-none d-xl-flex"><small>Note : We help you to Grow your Business</small></div>
                    <div className="top-link">
                        <a href="https://www.facebook.com/profile.php?id=61561684453435&mibextid=LQQJ4d" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-1"><i className="fab fa-facebook-f text-primary"></i></a>
                        {/* <a href="www.ikartsolution.com" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-1"><i className="fab fa-twitter text-primary"></i></a> */}
                        <a href="https://www.instagram.com/iotfied?igsh=MW81OG1kaDk4d25taA%3D%3D&utm_source=qr" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-instagram text-primary"></i></a>
                        <a href="https://www.linkedin.com/company/iotfied" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-linkedin-in text-primary"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
}