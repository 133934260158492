import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle,
    faUser,
    faExclamationCircle,
    faLightbulb, faCogs,
    faIndustry, faHeart, faUsers, faAward,
    faCity, faLeaf,
    faHeartbeat,
    faTractor,
    faTruck,
    faBolt
} from '@fortawesome/free-solid-svg-icons';
import logoimg from '../assets/logo Samples/v3/f2@2x.png';

const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = styles.menuItemHover.backgroundColor;
    e.target.style.color = styles.menuItemHover.color;
};

const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = '';
    e.target.style.color = styles.menuItem.color;
};

export default function NavBar() {
    const navigate = useNavigate();
    const [dropdownHovered, setDropdownHovered] = useState(false);

    const handleNavigation = (path, state) => {
        navigate(path, { state });
        setDropdownHovered(false);
    };

    return (
        <div className="container-fluid bg-primary">
            <div className="container">
                <nav className="navbar navbar-dark navbar-expand-lg py-0">
                    <Link to="/" className="navbar-brand">
                        <img src={logoimg} className="img-fluid w-100 rounded" style={styles.logoimg} alt="logoimg" />
                        {/* <h1 className="text-white fw-bold d-block">IoT<span className="text-secondary">fied</span></h1> */}
                    </Link>
                    <button
                        type="button"
                        className="navbar-toggler me-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
                        <div className="navbar-nav ms-auto mx-xl-auto p-0">
                            <NavLink to="/" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active text-secondary" : "")}>
                                Home
                            </NavLink>
                            <NavLink to="/about" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
                                About
                            </NavLink>
                            <div
                                className={({ isActive }) => "nav-item dropdown" + (isActive ? "" : "active")}
                                onMouseEnter={() => setDropdownHovered(true)}
                                onMouseLeave={() => setDropdownHovered(false)}
                            >
                                <NavLink
                                    to="#"
                                    className={({ isActive }) => "nav-link dropdown-toggle" + (isActive ? "" : "active")}
                                    id="navbarDropdown"
                                    role="button"
                                    aria-expanded={dropdownHovered ? "true" : "false"}
                                >
                                    Services
                                </NavLink>
                                <div className={"dropdown-menu" + (dropdownHovered ? " show" : "")} style={{ ...styles.dropdownMenu, display: dropdownHovered ? 'block' : 'none' }}>
                                    <div className="row g-5" style={styles.blocks}>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />Smart cities</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleNavigation("/template", { id: 2, screen: 'POS' })}>Parking</li>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleNavigation("/template", { id: 4, screen: 'EMSB' })}>Spot utility billing</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />Healthcare</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleNavigation("/template", { id: 5, screen: 'RFID' })}>Patient tracking</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />Transportation</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleNavigation("/template", { id: 1, screen: 'ITMS' })}>ITMS</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row g-5" style={styles.blocks}>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />Govt. Services</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleNavigation("/template", { id: 6, screen: 'E-Challan' })}>E_Challan</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-1">
                                            <h4 className="text-primary mt-1" style={styles.dropdownMenuTitles}><FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />HORECA</h4>
                                            <ul style={styles.ul}>
                                                <li style={styles.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleNavigation("/template", { id: 3, screen: 'ScanNDo' })}>ScanNDo</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NavLink to="/blog" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
                                Our Blog
                            </NavLink>
                            <NavLink to="/contact" className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")}>
                                Contact
                            </NavLink>
                        </div>
                    </div>
                    <div className="d-none d-xl-flex flex-shrink-0">
                        <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                            <Link to="#" className="position-relative animated tada infinite">
                                <i className="fa fa-phone-alt text-white fa-2x"></i>
                                <div className="position-absolute" style={{ top: '-7px', left: '20px' }}>
                                    <span><i className="fa fa-comment-dots text-secondary"></i></span>
                                </div>
                            </Link>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="text-white-50">Have any questions?</span>
                            <span className="text-secondary">Call: + 918320116640</span>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

const styles = {
    logoimg: {
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '55px',
    },
    dropdownMenu: {
        position: 'absolute',
        zIndex: 1000,
        minWidth: '45rem',
        minheight: '40rem',
        padding: '1rem',
        fontSize: '1rem',
        color: '#333',
        textAlign: 'left',
        border: 'none',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
    },
    blocks: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
    },
    dropdownMenuTitles: {
        color: '#f55b14',
        fontSize: '19px',
        fontWeight: 'bold',
        borderBottom: '2px solid #f55b14',
    },
    iconBeforeText: {
        marginRight: '5px',
    },
    menuItem: {
        padding: '3px',
        textDecoration: 'none',
        color: '#333',
        display: 'block',
        transition: 'background-color 0.3s ease, color 0.3s ease',
    },
    menuItemHover: {
        backgroundColor: '#f55b14',
        borderRadius: '0.5rem',
        color: '#fff',
    },
    ul: {
        paddingLeft: '0',
        listStyle: 'none',
    },
    '@media (max-width: 768px)': {
        dropdownMenu: {
            minWidth: '30rem',
            fontSize: '0.9rem',
        },
    },
    '@media (max-width: 576px)': {
        dropdownMenu: {
            minWidth: '20rem',
            fontSize: '0.8rem',
        },
    },
};
