import React, { useEffect, useRef, useState } from 'react'
import WOW from 'wowjs';
import "../Components/home.css"

export default function Team() {
    const carouselRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const items = 4;
    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items) % items);
    };
    return (
        <div className="container-fluid py-5 mb-5 team">
        <div className="container">
            <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
                <h5 className="text-primary">Our Team</h5>
                <h1>Meet our expert Team</h1>
            </div>
            <div className="team-carousel" ref={carouselRef} style={{ transform: `translateX(-${currentIndex * 100 / 3}%)` }}>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-2.jpg" className="img-fluid w-100 rounded-circle" alt="Team member 2" />
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Virendra </h4>
                                <p className="m-0">Virendra leads our Hardware Research and Development efforts with a passion for innovation and a commitment to excellence. With over 15 years of experience in the hardware industry, he brings a wealth of knowledge and a proven track record of successful product development to our company.</p>
                            </div>
                            <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-3.jpg" className="img-fluid w-100 rounded-circle" alt="Team member 3" />
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Krunal Chavda</h4>
                                <p className="m-0">Krunal brings over 15 years of experience in IT & Automation domain to our company. He is the driving force behind our technological vision and strategy. His passion for innovation and commitment to excellence ensures that our products and services are always at the cutting edge of industry standards.</p>
                            </div>
                            <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-4.jpg" className="img-fluid w-100 rounded-circle" alt="Team member 4" />
                            </div>
                            <div className="team-name text-center py-3">
                                <h4 className="">Krunal Chavda</h4>
                                <p className="m-0">Krunal brings over 15 years of experience in IT & Automation domain to our company. He is the driving force behind our technological vision and strategy. His passion for innovation and commitment to excellence ensures that our products and services are always at the cutting edge of industry standards.</p>
                            </div>
                            <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded team-item">
                    <div className="team-content">
                        <div className="team-img-icon">
                            <div className="team-img rounded-circle">
                                <img src="img/team-4.jpg" className="img-fluid w-100 rounded-circle" alt="Team Member 4" />
                            </div>
                            <div className="team-name text-center py-3">
                                <h4>Full Name</h4>
                                <p className="m-0">Designation</p>
                            </div>
                            <div className="team-icon d-flex justify-content-center pb-4">
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-nav">
                <button onClick={prevSlide}>&lt;</button>
                <button onClick={nextSlide}>&gt;</button>
            </div>
        </div>
    </div>
    );
}
