import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logoimg from '../assets/logo Samples/v3/f2@2x.png';

export default function Footer() {

    const scrollToTop = () => {
        window.scrollTo(0, 0); // scroll to top
    }

    return (
        <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
            <div className="container pt-5 pb-4">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6">
                        <Link to="/" onClick={scrollToTop}>
                            <img src={logoimg} className="img-fluid fw-bold d-block" style={{ height: 'auto', maxWidth: '100%', maxHeight: '75px' }} alt="logoimg" />
                            {/* <h1 className="text-white fw-bold d-block">IoT<span className="text-secondary">fied</span></h1> */}
                        </Link>
                        <p className="mt-4 text-light">IoTfied was founded with a vision to transform the way businesses operate through advanced IoT technologies. Our journey began with a simple yet powerful idea: to bridge the gap between the physical and digital worlds, creating smarter, more efficient systems that drive growth and innovation. Today, we stand at the forefront of IoT innovation, committed to delivering cutting-edge solutions that exceed industry standards.</p>
                        <div className="d-flex hightech-link">
                            <a href="https://www.facebook.com/profile.php?id=61561684453435&mibextid=LQQJ4d" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-facebook-f text-primary"></i></a>
                            {/* <a href="" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-twitter text-primary"></i></a> */}
                            <a href="https://www.instagram.com/iotfied?igsh=MW81OG1kaDk4d25taA%3D%3D&utm_source=qr" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-instagram text-primary"></i></a>
                            <a href="https://www.linkedin.com/company/iotfied" target="_blank" className="btn-light nav-fill btn btn-square rounded-circle me-0"><i className="fab fa-linkedin-in text-primary"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <a href="#" className="h3 text-secondary">Short Help Link</a>
                        <div className="mt-4 d-flex flex-column short-link">
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Home</a>
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>About us</a>
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Services</a>
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Our Blog</a>
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Terms Of use</a>
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Privacy Policy</a>
                            <a href="" className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2"></i>Contact</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <a href="#" className="h3 text-secondary">Contact Us</a>
                        <div className="text-white mt-4 d-flex flex-column contact-link">
                            <a href="#" className="pb-3 text-light border-bottom border-primary"><i className="fas fa-map-marker-alt text-secondary me-2"></i> Design center : 708 HareKrishna Complex, Opposite Kothawala Flats, Paldi, Ahmedabad, Gujarat, India 380006</a>
                            <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-map-marker-alt text-secondary me-2"></i> Development center : 425, Advance Business Park, Shahibaug, Ahmedabad-380004. India</a>
                            <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-phone-alt text-secondary me-2"></i> (+91)832 0116 640</a>
                            <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-envelope text-secondary me-2"></i> sales@iotfied.com</a>
                        </div>
                    </div>
                </div>
                <hr className="text-light mt-5 mb-4" />
                <div className="row">
                    <div className="col-md-6 text-center text-md-start">
                        <span className="text-light"><a href="https://iotfied.com/" className="text-secondary"><i className="fas fa-copyright text-secondary me-2"></i>iotfied.com</a></span>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <span className="text-light">Designed By<a href="https://www.ikartsolutions.com/" className="text-secondary"> iKart Solutions</a></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
