import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import he from 'he';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

export default function Blog() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const url = 'https://public-api.wordpress.com/wp/v2/sites/iotfiedblog.wordpress.com/posts';
        axios.get(url).then((res) => {
            setPosts(res.data);
        }).catch((error) => {
            console.error("There was an error fetching the posts:", error);
        });
    }, []);

    const getFirst400Chars = (content) => {
        const cleanContent = content.replace(/<[^>]*>?/gm, '');
        const trimmedContent = cleanContent.length > 400 ? cleanContent.substring(0, 400) + '...' : cleanContent;
        return trimmedContent;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "do MMMM yyyy", { locale: enUS });
    };

    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled up to a certain distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the visibility based on scroll
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div className="container-fluid services py-5 mb-5">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
                        <h5 className="text-primary">Our Blog</h5>
                        <h1>Latest Blog & News</h1>
                    </div>
                    <div className="row g-5 services-inner">
                        {posts.slice(0, 6).map((post) => (
                            <div key={post.id} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                                <div className="services-item bg-light">
                                    <div className="p-4 text-center services-content">
                                        <div className="services-content-icon">
                                            <h4 className="mb-3" style={{ height: '175px', overflow: 'hidden' }}>{he.decode(post.title.rendered)}</h4>
                                            <p className="blog-date mb-3" style={styles.blogDate}>{formatDate(post.date)}</p>
                                            <p className="mb-4" style={{ ...styles.blogExcerpt, height: '265px', overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: getFirst400Chars(post.content.rendered) }}></p>
                                            <a href={post.link} target="_blank" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* scrollToTop Button */}
            <div className="scroll-to-top">
                {isVisible && (
                    <div onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faArrowUp} className="scroll-to-top-icon" />
                    </div>
                )}
            </div>
        </>
    );
}

const styles = {
    blogItem: {
        backgroundColor: '#edebeb',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        ':hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 15px 30px rgba(0, 0, 0, 0.1)',
        },
    },
    blogTitle: {
        borderBottom: '1px solid #f55b14',
        fontSize: '1.5rem',
        fontWeight: '700',
        color: '#343a40',
    },
    blogDate: {
        fontSize: '0.9rem',
        color: '#f55b14',
    },
    blogExcerpt: {
        fontSize: '1rem',
        color: '#495057',
    },
    readMoreBtn: {
        borderRadius: '10px',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#007bff',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        ':hover': {
            color: '#0056b3',
        },
    },
    blogComment: {
        background: '#007bff',
        color: '#fff',
    },
    blogCommentLink: {
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        ':hover': {
            color: '#dfe6e9',
        },
    },
    blogCommentSmall: {
        fontSize: '0.85rem',
    },
    textPrimary: {
        color: '#007bff !important',
    },
};
