import './App.css';
import { Route, Routes } from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import NavBar from './Components/NavBar';
import About from './Components/About';
import Services from './Components/Services';
import Projects from './Components/Projects';
import Blog from './Components/Blog';
import Team from './Components/Team';
import Testimonial from './Components/Testimonial';
import NotFound404 from './Components/NotFound404';
import Contact from './Components/Contact';
import Template from './Components/Template';

function App() {
  return (
    <>
      <Header />
      <NavBar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="template" element={<Template />} />
        <Route path="services" element={<Services />} />
        <Route path="projects" element={<Projects />} />
        <Route path="blog" element={<Blog />} />
        <Route path="team" element={<Team />} />
        <Route path="testimonial" element={<Testimonial />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
