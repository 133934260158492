import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OwlCarousel from 'react-owl-carousel';
import {
  faInfoCircle,
  faUser,
  faExclamationCircle,
  faLightbulb, faCogs,
  faIndustry, faHeart, faUsers, faAward,
  faCity, faLeaf,
  faHeartbeat,
  faTractor,
  faTruck,
  faBolt
} from '@fortawesome/free-solid-svg-icons'; // Import the necessary icons
import aboutimg1 from '../assets/img/about-1.jpg';
import textBackgoundImg from '../assets/img/BackgroundDesigns/pattern-4.png';
import '../assets/css/bootstrap.min.css';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

export default function About() {

  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to a certain distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the visibility based on scroll
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div style={styles.container}>
      <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
        <div className="container pt-3">
          <div className="row g-5">
            <div className="col-lg-8 col-md-3 col-sm-12 wow fadeIn" style={styles.textContent} data-wow-delay=".5s">
              <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faInfoCircle} style={styles.iconBeforeText} />About Us</h3>
              <section style={styles.section}>
                <p style={styles.sectionP}>IoTfiied is a frontrunner in the Internet of Things (IoT) revolution. We empower businesses to streamline operations with cutting-edge technologies. Our vision? To seamlessly connect the physical and digital, creating smarter, more efficient systems that fuel growth and innovation.</p>
                <p style={styles.sectionP}>In essence, we bridge the gap between your physical world and the power of data. This translates to significant gains for your business.</p>
              </section>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img src={aboutimg1} className="img-fluid w-100 rounded" alt="Web design project" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container-fluid services py-2 my-2">
          <div className="container py-3">
            <div className="text-center mx-auto pb-3 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px' }}>
              <h1 style={styles.sectionH3}><FontAwesomeIcon icon={faUser} style={styles.iconBeforeText} />Our Values</h1>
              <h5 className="text-primary mt-3">A Company Without Values is like a Body Without Soul <br /> At IoTfied, we're more than just machines. We're driven by values that make a difference.</h5>
            </div>
            <div className="row g-5 services-inner">
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                <div className="bg-light" style={{ height: '400px' }}>
                  <div className="p-4 text-center">
                    <div className="services-content-icon">
                      <FontAwesomeIcon icon={faLightbulb} size="7x" className="mb-4" />
                      <h4 className="mb-3">Innovation</h4>
                      <p className="mb-4">We are dedicated to continuously pushing the boundaries of what's possible with IoT technology. Our relentless pursuit of innovation ensures that we deliver cutting-edge solutions that drive progress and create new opportunities for our clients.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                <div className="bg-light" style={{ height: '400px' }}>
                  <div className="p-4 text-center">
                    <div className="services-content-icon">
                      <FontAwesomeIcon icon={faCogs} size="7x" className="mb-4" />
                      <h4 className="mb-3">Integrity</h4>
                      <p className="mb-4">We uphold the highest standards of honesty and transparency in all our actions. Integrity is the cornerstone of our business, fostering trust and building lasting relationships with our clients, partners, and employees.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                <div className="bg-light" style={{ height: '400px' }}>
                  <div className="p-4 text-center">
                    <div className="services-content-icon">
                      <FontAwesomeIcon icon={faUsers} size="7x" className="mb-4" />
                      <h4 className="mb-3">Customer Focus</h4>
                      <p className="mb-4">Our commitment to understanding and exceeding our clients' needs and expectations is unwavering. We place our customers at the center of everything we do, ensuring that our solutions are tailored to meet their unique challenges and drive their success.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-2 wow fadeIn" data-wow-delay=".3s">
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                <div className="bg-light" style={{ height: '400px' }}>
                  <div className="p-4 text-center">
                    <div className="services-content-icon">
                      <FontAwesomeIcon icon={faAward} size="7x" className="mb-4" />
                      <h4 className="mb-3">Excellence</h4>
                      <p className="mb-4">We are continuously striving for excellence in all our products, solutions, and services. Our dedication to quality ensures that we deliver outstanding results that meet the highest standards of performance and reliability.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                <div className="bg-light" style={{ height: '400px' }}>
                  <div className="p-4 text-center">
                    <div className="services-content-icon">
                      <FontAwesomeIcon icon={faLeaf} size="7x" className="mb-4" />
                      <h4 className="mb-3">Sustainability</h4>
                      <p className="mb-4">We promote sustainable practices through intelligent and efficient technology. Our solutions are designed to minimize environmental impact, helping businesses operate more sustainably and responsibly.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container-fluid testimonial py-3 my-3">
          <div className="container py-4">
            <div className="text-center mx-auto pb-3 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px' }}>
              <h1 style={styles.sectionH3}><FontAwesomeIcon icon={faExclamationCircle} style={styles.iconBeforeText} />Why Choose IoTfied?</h1>
            </div>
            <OwlCarousel
              className="owl-theme wow fadeIn"
              loop
              margin={10}
              nav
              autoplay
              autoplayTimeout={5000}
              data-wow-delay=".3s"
              items={2}
              responsive={{
                0: { items: 1 },
                600: { items: 2 }
              }}
            >
              <div className="testimonial-item border p-4" style={{ height: '300px' }}>
                <div className="d-flex align-items-center">
                  <div className="ms-1">
                    <h4 className="text-secondary">Tailored Solutions:</h4>
                  </div>
                </div>
                <div className="border-top mt-2 pt-3" style={styles.textContent}>
                  <p className="mb-0">At IoTfied, our commitment to delivering tailored IoT solutions goes beyond customization; we ensure seamless integration and scalable deployments that evolve with your business, empowering sustained growth and success.</p>
                </div>
              </div>
              <div className="testimonial-item border p-4" style={{ height: '300px' }}>
                <div className="d-flex align-items-center">
                  <div className="ms-1">
                    <h4 className="text-secondary">Cutting-Edge Technology:</h4>
                  </div>
                </div>
                <div className="border-top mt-2 pt-3" style={styles.textContent}>
                  <p className="mb-0">At IoTfied, our cutting-edge IoT solutions have consistently exceeded industry standards, driving transformative outcomes for businesses worldwide. With a focus on innovation and reliability, we continue to pioneer new possibilities in IoT technology, ensuring our clients achieve sustainable growth and operational excellence.</p>
                </div>
              </div>
              <div className="testimonial-item border p-4" style={{ height: '300px' }}>
                <div className="d-flex align-items-center">
                  <div className="ms-1">
                    <h4 className="text-secondary">Proven Track Record:</h4>
                  </div>
                </div>
                <div className="border-top mt-2 pt-3" style={styles.textContent}>
                  <p className="mb-0">Delivering Reliable IoT Solutions: IoTfied has a proven track record of successfully deploying robust IoT solutions across diverse industries, consistently exceeding client expectations with measurable improvements in efficiency, productivity, and operational performance. Our track record speaks volumes about our expertise and commitment to driving tangible business outcomes through innovative IoT technologies.</p>
                </div>
              </div>
              <div className="testimonial-item border p-4" style={{ height: '300px' }}>
                <div className="d-flex align-items-center">
                  <div className="ms-1">
                    <h4 className="text-secondary">Customer-Centric Approach:</h4>
                  </div>
                </div>
                <div className="border-top mt-2 pt-3" style={styles.textContent}>
                  <p className="mb-0">Your Success, Our Priority. At IoTfied, we place our clients at the heart of everything we do with our customer-centric approach. From initial consultation to ongoing support, we prioritize understanding your unique challenges and goals to deliver tailored IoT solutions that drive your success.</p>
                </div>
              </div>
              <div className="testimonial-item border p-4" style={{ height: '300px' }}>
                <div className="d-flex align-items-center">
                  <div className="ms-1">
                    <h4 className="text-secondary">Sustainability Focus:</h4>
                  </div>
                </div>
                <div className="border-top mt-2 pt-3" style={styles.textContent}>
                  <p className="mb-0">Driving Responsible Innovation: At IoTfied, sustainability is at the core of our innovation strategy. We are dedicated to integrating smart, eco-friendly practices into our IoT solutions, ensuring that businesses not only thrive but also contribute positively to the environment.</p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>

      <div className="container-fluid py-1 position-relative" style={{ width: '100%', height: '100%' }}>
        <div className="container pt-2">
          <div className="row g-5">
            <div className="col-lg-12 col-md-3 col-sm-12 wow fadeIn" style={styles.textContent} data-wow-delay=".5s">
              <h3 style={styles.sectionH3}><FontAwesomeIcon icon={faLightbulb} style={styles.iconBeforeText} />Our Areas of Expertise</h3>
              <section className="col-md-12" style={styles.section}>
                <p className="text-primary" style={styles.sectionP}>Driving Innovation Across Industries: At IoTfied, our deep expertise and innovative approach enable us to deliver tailored IoT solutions that address specific challenges and capitalize on opportunities in each industry. Partner with us to unlock the full potential of IoT technology and achieve sustainable growth and operational excellence.</p>
              </section>

              <section className="featured-section pb-5">
                <div className="auto-container">
                  <div className="row clearfix">
                    <div className="feature-block-two col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                      <div className="inner-box">
                        <div className="content-box">
                          <div className="content">
                            <h4 className="title" style={styles.cardTitle}><FontAwesomeIcon icon={faCity} style={styles.iconBeforeText} />Smart Cities</h4>
                            <div style={styles.cardText}>

                              <h5 className="text-primary mt-4">Smart Cities Solutions: Enhancing Urban Efficiency and Sustainability</h5>
                              <p style={styles.sectionP}>Transform urban living with IoTfied's advanced IoT solutions designed to make cities smarter, more efficient, and sustainable.</p>

                              <h5 className="text-secondary mt-4">Our expertise encompasses:</h5>

                              <h5 className="text-primary mt-4">Smart Infrastructure Management</h5>
                              <p style={styles.sectionP}>Deploy IoT sensors and devices for real-time monitoring and efficient management of city infrastructure. Enhance reliability and reduce maintenance costs with proactive insights.</p>

                              <h5 className="text-primary mt-4">Intelligent Traffic Systems</h5>
                              <p style={styles.sectionP}>Implement cutting-edge IoT solutions to optimize traffic flow, minimize congestion, and enhance urban mobility. Utilize data analytics to improve commuter experiences and reduce travel time.</p>

                              <h5 className="text-primary mt-4">Energy Efficiency Solutions</h5>
                              <p style={styles.sectionP}>Optimize energy consumption with IoT-enabled smart grids and energy-efficient solutions. Integrate renewable energy sources and predictive analytics for sustainable urban development.</p>

                              <h5 className="text-primary mt-4">Public Safety and Security</h5>
                              <p style={styles.sectionP}>Enhance public safety through connected surveillance systems and IoT-driven emergency response solutions. Monitor critical areas in real-time and streamline emergency responses for safer communities.</p>

                              <h5 className="text-primary mt-4">Why Choose IoTfied for Smart City Solutions?</h5>
                              <ul style={styles.ul}>
                                <li>Innovation and Expertise: Leading-edge IoT technologies tailored for urban challenges.</li>
                                <li>Efficiency and Sustainability: Solutions designed to reduce environmental impact and operational costs.</li>
                                <li>Scalability and Integration: Seamless integration into existing city frameworks with scalable deployments.</li>
                                <li>Customer-Centric Approach: Tailored solutions that meet specific urban needs and enhance quality of life.</li>
                              </ul>

                              <h5 className="text-primary mt-4">Partner with IoTfied for Smart City Innovation</h5>
                              <p style={styles.sectionP}>Unlock the potential of smart city technologies with IoTfied's expertise in IoT solutions tailored to enhance urban efficiency and sustainability. Contact us today to explore how we can empower your city's transformation with cutting-edge IoT solutions.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="feature-block-two col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                      <div className="inner-box">
                        <div className="content-box">
                          <div className="content">
                            <h4 className="title" style={styles.cardTitle}><FontAwesomeIcon icon={faIndustry} style={styles.iconBeforeText} />Industrial IoT</h4>
                            <div style={styles.cardText}>

                              <h5 className="text-primary mt-4">Industrial IoT Solutions: Enhancing Manufacturing Efficiency and Productivity</h5>
                              <p style={styles.sectionP}>IoTfied specializes in Industrial IoT (IIoT) solutions designed to optimize manufacturing and production processes, driving significant improvements in efficiency and operational performance.</p>

                              <h5 className="text-secondary mt-4">Our expertise spans across:</h5>

                              <h5 className="text-primary mt-4">Predictive Maintenance</h5>
                              <p style={styles.sectionP}>Utilize IoT sensors and advanced data analytics to predict equipment failures before they occur, enabling proactive maintenance scheduling and reducing downtime.</p>

                              <h5 className="text-primary mt-4">Real-Time Monitoring</h5>
                              <p style={styles.sectionP}>Implement continuous monitoring systems to oversee production processes in real-time, ensuring consistent quality control and operational efficiency.</p>

                              <h5 className="text-primary mt-4">Asset Tracking and Management</h5>
                              <p style={styles.sectionP}>Deploy IoT solutions for real-time tracking and management of assets throughout the supply chain, optimizing inventory control and enhancing logistical efficiency.</p>

                              <h5 className="text-primary mt-4">Automation and Robotics</h5>
                              <p style={styles.sectionP}>Enhance manufacturing capabilities with IoT-enabled machinery and robotics, automating repetitive tasks and improving overall production efficiency.</p>

                              <h5 className="text-primary mt-4">Why Choose IoTfied for Industrial IoT?</h5>
                              <ul style={styles.ul}>
                                <li>Proven Expertise: Extensive experience in delivering IIoT solutions tailored to manufacturing needs.</li>
                                <li>Enhanced Efficiency: Solutions designed to streamline operations and minimize production downtime.</li>
                                <li>Scalable Solutions: Scalable deployments that grow with your business and adapt to changing industry demands.</li>
                                <li>Data-Driven Insights: Utilize real-time data analytics for informed decision-making and continuous process improvement.</li>
                              </ul>

                              <h5 className="text-primary mt-4">Partner with IoTfied for Manufacturing Excellence</h5>
                              <p style={styles.sectionP}>Transform your manufacturing operations with IoTfied's cutting-edge IIoT solutions. Contact us today to discover how we can help you achieve higher productivity, reduced costs, and enhanced operational efficiency through innovative IoT technologies.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="feature-block-two col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                      <div className="inner-box">
                        <div className="content-box">
                          <div className="content">
                            <h4 className="title" style={styles.cardTitle}><FontAwesomeIcon icon={faHeartbeat} style={styles.iconBeforeText} />Healthcare IoT</h4>
                            <div style={styles.cardText}>

                              <h5 className="text-primary mt-4">Healthcare IoT Solutions: Transforming Patient Care with Innovation</h5>
                              <p style={styles.sectionP}>IoTfied specializes in leveraging IoT technology to revolutionize healthcare, enhancing patient outcomes and operational efficiency across the industry.</p>

                              <h5 className="text-secondary mt-4">Our expertise encompasses:</h5>

                              <h5 className="text-primary mt-4">Remote Health Monitoring</h5>
                              <p style={styles.sectionP}>Developing IoT-enabled wearables and devices for continuous monitoring of vital signs and health metrics, enabling proactive care and early intervention.</p>

                              <h5 className="text-primary mt-4">Telemedicine Solutions</h5>
                              <p style={styles.sectionP}>Facilitating remote consultations and diagnostics through integrated IoT platforms, connecting patients with healthcare professionals for timely and efficient care delivery.</p>

                              <h5 className="text-primary mt-4">Smart Medical Devices</h5>
                              <p style={styles.sectionP}>Innovating with connected medical devices that collect and transmit real-time health data, empowering healthcare providers to make informed decisions and personalize treatment plans.</p>

                              <h5 className="text-primary mt-4">Data Analytics for Healthcare</h5>
                              <p style={styles.sectionP}>Harnessing big data analytics to analyze health data, generate actionable insights, and optimize patient care strategies for improved outcomes and efficiency.</p>

                              <h5 className="text-primary mt-4">Why Choose IoTfied for Healthcare IoT?</h5>
                              <ul style={styles.ul}>
                                <li>Patient-Centric Solutions: Tailored IoT solutions designed to enhance patient engagement and satisfaction.</li>
                                <li>Compliance and Security: Ensuring adherence to healthcare regulations and implementing robust security measures to protect patient data.</li>
                                <li>Integration and Interoperability: Seamless integration with existing healthcare IT systems for enhanced operational efficiency and workflow optimization.</li>
                                <li>Continuous Innovation: Staying at the forefront of healthcare technology advancements to deliver cutting-edge solutions that meet evolving industry needs.</li>
                              </ul>

                              <h5 className="text-primary mt-4">Partner with IoTfied for Healthcare Transformation</h5>
                              <p style={styles.sectionP}>Empower your healthcare organization with IoTfied's advanced IoT solutions. Contact us today to explore how our innovative healthcare IoT technologies can optimize patient care, streamline operations, and drive better health outcomes.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="feature-block-two col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                      <div className="inner-box">
                        <div className="content-box">
                          <div className="content">
                            <h4 className="title" style={styles.cardTitle}><FontAwesomeIcon icon={faTractor} style={styles.iconBeforeText} />Agricultural IoT</h4>
                            <div style={styles.cardText}>

                              <h5 className="text-primary mt-4">Agricultural IoT Solutions: Empowering Modern Farming Practices</h5>
                              <p style={styles.sectionP}>IoTfied specializes in agricultural IoT solutions that revolutionize farming practices, enabling farmers to increase productivity, reduce costs, and promote sustainable agriculture.</p>

                              <h5 className="text-secondary mt-4">Our expertise includes:</h5>

                              <h5 className="text-primary mt-4">Precision Farming</h5>
                              <p style={styles.sectionP}>Implementing IoT sensors and drones for precise monitoring of soil conditions, crop health, and weather patterns. Optimize planting, fertilization, and pest control strategies for improved yield and resource efficiency.</p>

                              <h5 className="text-primary mt-4">Smart Irrigation Systems</h5>
                              <p style={styles.sectionP}>Developing automated irrigation solutions that adjust water usage based on real-time data and weather forecasts. Enhance crop health and yield while conserving water resources and reducing operational costs.</p>

                              <h5 className="text-primary mt-4">Livestock Monitoring and Management</h5>
                              <p style={styles.sectionP}>Utilizing IoT devices to monitor livestock health, behavior, and location. Improve animal welfare, optimize feeding schedules, and detect health issues early to ensure healthier livestock and increased productivity.</p>

                              <h5 className="text-primary mt-4">Supply Chain Optimization</h5>
                              <p style={styles.sectionP}>Enhancing traceability and efficiency across the agricultural supply chain with IoT-enabled solutions. Track inventory levels, monitor transportation conditions, and ensure product quality from farm to market.</p>

                              <h5 className="text-primary mt-4">Why Choose IoTfied for Agricultural IoT?</h5>
                              <ul style={styles.ul}>
                                <li>Expertise in Agriculture: Deep understanding of farming challenges and opportunities, with tailored solutions that address specific agricultural needs.</li>
                                <li>Sustainability Focus: Promoting sustainable farming practices through optimized resource management and environmental stewardship.</li>
                                <li>Scalable Solutions: Solutions that scale with your farm operations, from small family farms to large agricultural enterprises.</li>
                                <li>Data-Driven Insights: Utilizing IoT data analytics to provide actionable insights for informed decision-making and continuous improvement.</li>
                              </ul>

                              <h5 className="text-primary mt-4">Partner with IoTfied for Agricultural Innovation</h5>
                              <p style={styles.sectionP}>Transform your farming operations with IoTfied's advanced agricultural IoT solutions. Contact us today to learn more about how our innovative technologies can help you achieve higher yields, reduce costs, and promote sustainable farming practices.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="feature-block-two col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                      <div className="inner-box">
                        <div className="content-box">
                          <div className="content">
                            <h4 className="title" style={styles.cardTitle}><FontAwesomeIcon icon={faTruck} style={styles.iconBeforeText} />Transportation</h4>
                            <div style={styles.cardText}>

                              <h5 className="text-primary mt-4">Transportation IoT Solutions: Enhancing Safety, Efficiency, and Connectivity</h5>
                              <p style={styles.sectionP}>IoTfied specializes in designing and deploying IoT solutions that revolutionize transportation systems, improving safety, efficiency, and connectivity across various sectors.</p>

                              <h5 className="text-secondary mt-4">Our expertise covers:</h5>

                              <h5 className="text-primary mt-4">Fleet Management Solutions</h5>
                              <p style={styles.sectionP}>Implementing IoT technologies for real-time tracking, monitoring, and management of vehicle fleets. Optimize fleet operations, reduce fuel costs, and enhance driver safety with actionable insights.</p>

                              <h5 className="text-primary mt-4">Smart Logistics Systems</h5>
                              <p style={styles.sectionP}>Enhancing logistics operations with IoT-enabled tracking and management systems. Improve supply chain visibility, optimize route planning, and streamline warehouse operations for increased efficiency.</p>

                              <h5 className="text-primary mt-4">Connected Vehicles</h5>
                              <p style={styles.sectionP}>Developing solutions for vehicle-to-vehicle (V2V) and vehicle-to-infrastructure (V2I) communication. Enable safer driving conditions, reduce traffic congestion, and enhance overall transportation network efficiency.</p>

                              <h5 className="text-primary mt-4">Public Transit Enhancements</h5>
                              <p style={styles.sectionP}>Improving public transportation systems with real-time data analytics and IoT-enabled management tools. Enhance passenger experience, optimize scheduling, and improve operational reliability.</p>

                              <h5 className="text-primary mt-4">Why Choose IoTfied for Transportation IoT?</h5>
                              <ul style={styles.ul}>
                                <li>Comprehensive Expertise: Deep understanding of transportation challenges and opportunities, with tailored solutions that address specific operational needs.</li>
                                <li>Safety and Efficiency: Enhance transportation safety and operational efficiency with IoT-driven insights and predictive analytics.</li>
                                <li>Scalable Solutions: Solutions that scale with your transportation network, from local transit authorities to global logistics providers.</li>
                                <li>Integration and Interoperability: Seamless integration with existing transportation infrastructures for enhanced connectivity and operational synergy.</li>
                              </ul>

                              <h5 className="text-primary mt-4">Partner with IoTfied for Transportation Innovation</h5>
                              <p style={styles.sectionP}>Transform your transportation systems with IoTfied's advanced IoT solutions. Contact us today to discover how our innovative technologies can help you achieve safer, more efficient, and connected transportation operations.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="feature-block-two col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                      <div className="inner-box">
                        <div className="content-box">
                          <div className="content">
                            <h4 className="title" style={styles.cardTitle}><FontAwesomeIcon icon={faBolt} style={styles.iconBeforeText} />Energy and Utilities</h4>
                            <div style={styles.cardText}>

                              <h5 className="text-primary mt-4">Energy and Utilities IoT Solutions: Driving Efficiency and Sustainability</h5>
                              <p style={styles.sectionP}>IoTfied specializes in delivering IoT solutions that empower energy and utility companies to optimize operations, improve service delivery, and promote sustainability.</p>

                              <h5 className="text-secondary mt-4">Our expertise encompasses:</h5>

                              <h5 className="text-primary mt-4">Smart Grid Solutions</h5>
                              <p style={styles.sectionP}>Implementing IoT-enabled smart grids to enhance energy distribution efficiency, reduce outages, and integrate renewable energy sources seamlessly.</p>

                              <h5 className="text-primary mt-4">Energy Monitoring and Management</h5>
                              <p style={styles.sectionP}>Developing IoT solutions for real-time monitoring, analysis, and optimization of energy consumption. Enable proactive energy management strategies to reduce costs and enhance efficiency.</p>

                              <h5 className="text-primary mt-4">Integration of Renewable Energy</h5>
                              <p style={styles.sectionP}>Integrating IoT technology with renewable energy sources such as solar and wind power. Optimize energy production and storage for increased reliability and sustainability.</p>

                              <h5 className="text-primary mt-4">Utility Management Systems</h5>
                              <p style={styles.sectionP}>Enhancing water and gas utility management with IoT sensors and analytics. Monitor infrastructure health, detect leaks early, and improve resource allocation for efficient service delivery.</p>

                              <h5 className="text-primary mt-4">Why Choose IoTfied for Energy and Utilities?</h5>
                              <ul style={styles.ul}>
                                <li>Industry Expertise: Deep understanding of energy and utility sector challenges, with tailored solutions that address specific operational needs.</li>
                                <li>Sustainability Focus: Promoting sustainable practices through optimized resource management and integration of renewable energy sources.</li>
                                <li>Scalable Solutions: Solutions designed to scale with your utility operations, from local providers to large-scale utility companies.</li>
                                <li>Data-Driven Insights: Utilizing IoT data analytics for informed decision-making and continuous improvement in operational efficiency.</li>
                              </ul>

                              <h5 className="text-primary mt-4">Partner with IoTfied for Energy Efficiency</h5>
                              <p style={styles.sectionP}>Empower your energy and utility operations with IoTfied's advanced IoT solutions. Contact us today to explore how our innovative technologies can help you achieve operational excellence, reduce costs, and drive sustainability in energy management.</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* scrollToTop Button */}
      <div className="scroll-to-top">
        {isVisible && (
          <div onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} className="scroll-to-top-icon" />
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    backgroundImage: `url(${textBackgoundImg})`,
    backgroundSize: 'contain',
  },
  cards: {
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    backgroundColor: 'white',
    backgroundSize: 'cover',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    position: 'relative',
  },
  textContent: {
    fontSize: '18px',
    padding: '8px',
    textAlign: 'justify',
  },
  section: {
    padding: '20px 25px',
    borderRadius: '8px',
    transition: 'transform 0.3s ease-in-out',
  },
  cardTitle: {
    padding: '10px 25px',
    color: '#f55b14',
    fontSize: '24px',
    fontWeight: 'bold',
    borderBottom: '2px solid #f55b14',
  },
  cardText: {
    margin: '10px 25px',
    padding: '10px',
    textAlign: 'justify',
  },
  sectionH3: {
    padding: '10px 25px',
    color: '#f55b14',
    fontSize: '24px',
    fontWeight: 'bold',
    borderBottom: '2px solid #f55b14',
  },
  sectionP: {
    color: '#555',
    lineHeight: '1.8',
    margin: '10px 0',
    textAlign: 'justify',
  },
  iconBeforeText: {
    marginRight: '10px',
  },
  li: {
    color: '#555',
    lineHeight: '1.8',
    margin: '10px 0',
    textAlign: 'justify',
  },
  ul: {
    paddingLeft: '20px',
    marginBottom: '20px',
  },
  ol: {
    paddingLeft: '20px',
    marginBottom: '20px',
  },
  solutionImage: {
    margin: '25px',
    fontSize: '18px',
    padding: '10px',
    borderRadius: '18px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  architectureImg: {
    width: '100%',
    marginTop: '20px',
  },

  // Media Queries for Responsiveness
  '@media (max-width: 768px)': {
    headerContent: {
      width: 'calc(90% - 44px)',
    },
    iconContainer: {
      marginRight: '20px',
      marginTop: '20px',
    },
    headerH1: {
      fontSize: '28px',
    },
    headerH2: {
      fontSize: '20px',
    },
    sectionH3: {
      fontSize: '20px',
    },
    iconItem: {
      margin: '5px',
    },
    threeIcon: {
      width: '30px',
      height: '30px',
      padding: '10px',
    },
    iconText: {
      fontSize: '14px',
    },
  },
  '@media (max-width: 576px)': {
    headerContent: {
      width: 'calc(100% - 44px)',
      top: '40%',
      left: '10px',
    },
    iconContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '0',
    },
    headerH1: {
      fontSize: '24px',
    },
    headerH2: {
      fontSize: '18px',
    },
    sectionH3: {
      fontSize: '18px',
    },
    iconItem: {
      margin: '3px',
    },
    threeIcon: {
      width: '25px',
      height: '25px',
      padding: '8px',
    },
    iconText: {
      fontSize: '12px',
    },
  },
};

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
document.head.appendChild(styleSheet);
