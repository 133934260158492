import React, { useEffect, useRef, useState } from 'react'
import WOW from 'wowjs';
import 'animate.css/animate.min.css';
import "../Components/home.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import textBackgoundImg from '../assets/img/BackgroundDesigns/pattern-4.png';
import carousel1 from '../assets/img/carousel/IOT1.JPG';
import carousel2 from '../assets/img/carousel/IOT2.png';
import carousel3 from '../assets/img/carousel/IOT3.png';
import about1 from '../assets/img/about-1.jpg';
import about2 from '../assets/img/about-2.jpg';
import ITMSimg from '../assets/img/ITMS/image1.jpg';
import ParkingSystemimg from '../assets/img/ParkingSystem/image1.jpeg';
import ScanNDoimg from '../assets/img/ScanNDo/ScanNDo3.jpg';
import EMSBimg from '../assets/img/EMSB/emsb.png';
import RFIDimg from '../assets/img/RFID/rfidCoverimg.png';
import EChallanimg from '../assets/img/EChallan/coverimg.jpg';
import team2 from "../assets/img/virendraBhatt.jpeg";
import team3 from "../assets/img/krunalChavda.jpg";
import team4 from "../assets/img/chirag.jpg";
import emailjs from 'emailjs-com';
import axios from 'axios';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import he from 'he';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Box, Cpu, Code, Link } from 'react-feather';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

export default function Home() {
    const carouselRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const items = 4; // Total number of items

    const testimonialRef = useRef(null);
    const [testimonialIndex, setTestimonialIndex] = useState(0);
    const testimonials = 4; // Total number of testimonials
    const [captchaCode, setCaptchaCode] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items) % items);
    };

    const navigate = useNavigate();

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const url = 'https://public-api.wordpress.com/wp/v2/sites/iotfiedblog.wordpress.com/posts';
        axios.get(url).then((res) => {
            setPosts(res.data);
        }).catch((error) => {
            console.error("There was an error fetching the posts:", error);
        });
    }, []);

    const getFirst400Chars = (content) => {
        const cleanContent = content.replace(/<[^>]*>?/gm, '');
        const trimmedContent = cleanContent.length > 400 ? cleanContent.substring(0, 400) + '...' : cleanContent;
        return trimmedContent;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "do MMMM yyyy", { locale: enUS });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const [formData, setFormData] = useState({
        from_name: '',
        email: '',
        project: '',
        message: '',
        captcha: '',
    });

    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.from_name) formErrors.from_name = "Name is required";
        if (!formData.email) {
            formErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (!formData.project) formErrors.project = "Project is required";
        if (!formData.message) formErrors.message = "Message is required";
        if (!formData.captcha) {
            formErrors.captcha = "Captcha is required";
        } else if (formData.captcha !== captchaCode) {
            formErrors.captcha = "Captcha is incorrect";
        }
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            emailjs.sendForm('service_zp1ks29', 'template_ohepfdp', e.target, 'xoHSrk5H0nW1dSqcC')
                .then((result) => {
                    console.log(result.text);
                    alert("Success! Email sent successfully.");
                    setFormData({ from_name: '', email: '', project: '', message: '', captcha: '' });
                    generateCaptcha();
                }, (error) => {
                    console.error(error.text);
                });
        } else {
            setErrors(formErrors);
        }
    };

    // Show button when page is scrolled up to a certain distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the visibility based on scroll
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const generateCaptcha = () => {
        let character = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890';
        let captcha = '';
        for (let i = 0; i < 6; i++) {
            captcha += character.charAt(Math.floor(Math.random() * character.length));
        }
        setCaptchaCode(captcha);
    };

    useEffect(() => {
        generateCaptcha();
    }, []);

    const handleRefreshCaptcha = (e) => {
        e.preventDefault();
        generateCaptcha();
    };

    return (
        <>
            {/* Carousel */}
            <div className="container-fluid px-0">
                <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <img src={carousel1} className="img-fluid" alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img src={carousel2} className="img-fluid" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img src={carousel3} className="img-fluid" alt="Third slide" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            {/* About US */}
            <div className="container-fluid py-3 my-3">
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src={about1} className="img-fluid w-75 rounded" alt="" style={{ marginBottom: "25%" }} />
                                <div className="position-absolute w-75" style={{ top: "25%", left: "25%" }}>
                                    <img src={about2} className="img-fluid w-100 rounded" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                            <h1 className="mb-4">About Us</h1>
                            <p style={styles.projectText}>IoTfied was founded with a vision to transform the way businesses operate through advanced IoT technologies. Our journey began with a simple yet powerful idea: to bridge the gap between the physical and digital worlds, creating smarter, more efficient systems that drive growth and innovation. Today, we stand at the forefront of IoT innovation, committed to delivering cutting-edge solutions that exceed industry standards.</p>
                            <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                onClick={() => navigate("/about")}>More Details</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* divider */}
            <div className="container-fluid services pt-4 mt-4 pb-0 mb-0">
                <div className="container">
                    <div className="text-center mx-auto pb-2 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px', position: 'relative' }}>
                        <div style={styles.textLable}>
                            <h1 style={{ margin: 0, fontSize: '24px', color: 'white' }}>Our Projects</h1>
                        </div>
                        <div style={styles.titleDividerLine}></div>
                    </div>
                </div>
            </div>

            {/* Projects */}
            <div>

                <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
                    <div style={styles.projectBGimg}></div>
                    <div className="container pt-4">
                        <div className="row g-5">
                            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src={ITMSimg} className="img-fluid w-100 rounded" alt="Web design project" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <h5 className="text-primary">ITMS</h5>
                                <h1 className="mb-4">Integrated Transport Management Systems</h1>
                                <p style={styles.projectText}>ITMS stands for Integrated Transport Management System. It is an integrated system meant for transport hubs in smart cities. ITMS is developed to elevate the commuter’s overall experience using technology, attracting more people to use mass transport systems rather than personal vehicles for their daily commuting.</p>
                                <p style={styles.projectText} className="mb-4">ITMS puts an end to the chaotic commuting experience, such as standing in queues to get tickets, handling of cash for ticket issuance, endless waits for buses, and chaos during boarding. Live tracking of the bus fleet ensured that passengers can get precise timing of their bus arrival/deparature.</p>
                                <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                    onClick={() => navigate("/template", { state: { id: 1, screen: 'ITMS' } })}>More Details</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid services pt-4 mt-4 pb-0 mb-0">
                    <div className="container">
                        <div className="text-center mx-auto pb-2 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px', position: 'relative' }}>
                            <div style={styles.dividerCube}></div>
                            <div style={styles.dividerLine}></div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
                    <div style={styles.projectBGimg}></div>
                    <div className="container pt-4">
                        <div className="row g-5">
                            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <h5 className="text-primary">POS</h5>
                                <h1 className="mb-4">Smart Parking Ticketing Solution</h1>
                                <p style={styles.projectText}>The primary objective of the system is to streamline the process of issuing parking lot tickets and efficiently handling the traffic at exit gates. Our solution provides rapid issuance of printed tickets through POS machines, operates gates automatically upon payment, and calculates the fare accurately based on timing for which parking spot was used. All these results in overall efficient operation and a pleasing experience for vehicle owners. Also, digitizing the process of ticket issuance process plug the revenue leakages for operators</p>
                                <p style={styles.projectText} className="mb-4">This solution was developed keeping in the mind the crowded spots e.g. malls, multiplexes / theaters, stadiums, exhibition centers, etc. where the public foot fall is very high and  require efficient management of parking facilities.</p>
                                <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                    onClick={() => navigate("/template", { state: { id: 2, screen: 'POS' } })}>More Details</button>
                            </div>
                            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src={ParkingSystemimg} className="img-fluid w-100 rounded" alt="Web design project" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid services pt-4 mt-4 pb-0 mb-0">
                    <div className="container">
                        <div className="text-center mx-auto pb-2 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px', position: 'relative' }}>
                            <div style={styles.dividerCube}></div>
                            <div style={styles.dividerLine}></div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
                    <div style={styles.projectBGimg}></div>
                    <div className="container pt-4">
                        <div className="row g-5">
                            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src={ScanNDoimg} className="img-fluid w-100 rounded" alt="Web design project" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <h5 className="text-primary">ScanNDo</h5>
                                <h1 className="mb-4">QR Code Based Food Ordering n Delivery System</h1>
                                <p style={styles.projectText}>Scanndo enables restaurant business owners to bring their business online and create their online presence. Apart from this, scanndo can also be used to offer touch free ordering/dine in experience to diners at restaurants using QR codes. Scanndo offers complete online food ordering experience i.e. browsing of food menu, order cart, payment  processing, order status tracking, etc. Scanndo is ideal solution for small restaurant business owners to increase their business footprint through online medium.</p>
                                <p style={styles.projectText} className="mb-4">Scanndo is basically a SaaS solution developed keeping in mind the needs of the small restaurant business owners allowing them to increase their business foot print using online medium.</p>
                                <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                    onClick={() => navigate("/template", { state: { id: 3, screen: 'ScanNDo' } })}>More Details</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid services pt-4 mt-4 pb-0 mb-0">
                    <div className="container">
                        <div className="text-center mx-auto pb-2 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px', position: 'relative' }}>
                            <div style={styles.dividerCube}></div>
                            <div style={styles.dividerLine}></div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
                    <div style={styles.projectBGimg}></div>
                    <div className="container pt-4">
                        <div className="row g-5">
                            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <h5 className="text-primary">EMSB</h5>
                                <h1 className="mb-4">Electricity Meter Spot Billing</h1>
                                <p style={styles.projectText}>Spot billing for Power, Water & Gas type utility & Payment Collection system where meter readings are taken and bills are generated on-site and digital payment option also available for spot payment Collection which results in efficient payment collection operation overall. This system aims to enhance billing accuracy, reduce delays, and improve customer satisfaction.</p>
                                <p style={styles.projectText} className="mb-4">Auto reading of the consumption readings results in elemination of manual efforts/errors and on the spot payment collection increase the billing and payment collection efficiency.</p>
                                <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                    onClick={() => navigate("/template", { state: { id: 4, screen: 'EMSB' } })}>More Details</button>
                            </div>
                            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src={EMSBimg} className="img-fluid w-100 rounded" alt="Web design project" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid services pt-4 mt-4 pb-0 mb-0">
                    <div className="container">
                        <div className="text-center mx-auto pb-2 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px', position: 'relative' }}>
                            <div style={styles.dividerCube}></div>
                            <div style={styles.dividerLine}></div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
                    <div style={styles.projectBGimg}></div>
                    <div className="container pt-4">
                        <div className="row g-5">
                            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src={RFIDimg} className="img-fluid w-100 rounded" alt="Web design project" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <h5 className="text-primary">RFID</h5>
                                <h1 className="mb-4">Patient Tracking System</h1>
                                <p style={styles.projectText}>An RFID-based patient tracking system with UHF RFID Reader & Tag (wristbands) is a technology solution used in healthcare settings to monitor and manage the movements and location of patients within a hospital or Campus. This system leverages Radio Frequency Identification (RFID) technology to provide real-time tracking and identification of patients, enhancing safety, efficiency, and overall patient care. </p>
                                <p style={styles.projectText} className="mb-4">In RFID based Patient Tracking system, each patient is given a wristband with RFID tag at the time of admitting and each ward's entrance and exit are fitted with RFID scanners. This system enhances patient safety, improves operational efficiency, and ensures better management of patient movements within the hospital. </p>
                                <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                    onClick={() => navigate("/template", { state: { id: 5, screen: 'RFID' } })}>More Details</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid services pt-4 mt-4 pb-0 mb-0">
                    <div className="container">
                        <div className="text-center mx-auto pb-2 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '1350px', position: 'relative' }}>
                            <div style={styles.dividerCube}></div>
                            <div style={styles.dividerLine}></div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-4 position-relative" style={{ width: '100%', height: '100%' }}>
                    <div style={styles.projectBGimg}></div>
                    <div className="container pt-4">
                        <div className="row g-5">
                            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                                <h5 className="text-primary">E-Challan</h5>
                                <h1 className="mb-4">Smart E-Challan System</h1>
                                <p style={styles.projectText}>A smart e-challan is an system used by traffic authorities to issue and manage fines for traffic violations on the spot.</p>
                                <p style={styles.projectText} className="mb-4">The system leverages advanced technologies such as automated cameras, handheld devices, and digital payment systems to streamline the process of recording violations, issuing fines, and collecting payments. The implementation of smart e-challan systems aims to enhance the efficiency, accuracy, and effectiveness of traffic enforcement, ultimately improving road safety and compliance with traffic regulations.</p>
                                <button className="btn btn-secondary rounded-pill px-5 py-3 text-white"
                                    onClick={() => navigate("/template", { state: { id: 6, screen: 'E-Challan' } })}>More Details</button>
                            </div>
                            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="project-item">
                                    <div className="project-img">
                                        <img src={EChallanimg} className="img-fluid w-100 rounded" alt="Web design project" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Services */}
            <div className="container-fluid services py-5 mb-5">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
                        <h5 className="text-primary">Our Services</h5>
                        <h1>Services Built Specifically For Your Business</h1>
                    </div>
                    <div className="row g-5 services-inner">
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
                            <div className="services-item bg-white">
                                <div className="p-4 text-center services-content">
                                    <div className="services-content-icon">
                                        <i><Link size={100} className="mb-4" /></i>
                                        <h4 className="mb-3" style={{ height: '50px' }}>System Integration</h4>
                                        <p className="mb-4">Seamlessly connect and integrate your systems for a cohesive and efficient workflow. Our expertise ensures smooth interoperability between diverse technologies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
                            <div className="services-item bg-white">
                                <div className="p-4 text-center services-content">
                                    <div className="services-content-icon">
                                        <i><Box size={100} className="mb-4" /></i>
                                        <h4 className="mb-3" style={{ height: '50px' }}>Product Development</h4>
                                        <p className="mb-4">From concept to market, we drive innovative product development tailored to your needs. Our team delivers high-quality solutions that align with your vision.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
                            <div className="services-item bg-white">
                                <div className="p-4 text-center services-content">
                                    <div className="services-content-icon">
                                        <i><Cpu size={100} className="mb-4" /></i>
                                        <h4 className="mb-3" style={{ height: '50px' }}>Hardware Designing</h4>
                                        <p className="mb-4">Crafting cutting-edge hardware designs that power the future of technology. Our precision engineering ensures robust and reliable performance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".9s">
                            <div className="services-item bg-white">
                                <div className="p-4 text-center services-content">
                                    <div className="services-content-icon">
                                        <i><Code size={100} className="mb-4" /></i>
                                        <h4 className="mb-3" style={{ height: '50px' }}>Software Development</h4>
                                        <p className="mb-4">Developing custom software solutions to streamline your operations and enhance user experience. Our agile methodology ensures rapid and efficient delivery.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Team */}
            <div className="container-fluid py-3 mb-3 team">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
                        <h5 className="text-primary">Our Team</h5>
                        <h1>Meet our expert Team</h1>
                    </div>
                    <div className="team-carousel" ref={carouselRef} style={{ transform: `translateX(-${currentIndex * 100 / 3}%)` }}>
                        <div className="rounded team-item">
                            <div className="team-content">
                                <div className="team-img-icon">
                                    <div className="team-img rounded-circle">
                                        <img src={team2} className="img-fluid w-100 rounded-circle" alt="Team member 2" />
                                    </div>
                                    <div className="team-name text-center py-3">
                                        <h4 className="">Virendra Bhatt</h4>
                                        <p style={styles.projectText} className="m-0">Virendra leads our Hardware Research and Development efforts with a passion for innovation and a commitment to excellence. With over 15 years of experience in the hardware industry, he brings a wealth of knowledge and a proven track record of successful product development to our company.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rounded team-item">
                            <div className="team-content">
                                <div className="team-img-icon">
                                    <div className="team-img rounded-circle">
                                        <img src={team3} className="img-fluid w-100 rounded-circle" alt="Team member 3" />
                                    </div>
                                    <div className="team-name text-center py-3">
                                        <h4 className="">Krunal Chavda</h4>
                                        <p style={styles.projectText} className="m-0">Krunal brings over 15 years of experience in IT & Automation domain to our company. He is the driving force behind our technological vision and strategy. His passion for innovation and commitment to excellence ensures that our products and services are always at the cutting edge of industry standards.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rounded team-item">
                            <div className="team-content">
                                <div className="team-img-icon">
                                    <div className="team-img rounded-circle">
                                        <img src={team4} className="img-fluid w-100 rounded-circle" alt="Team member 4" />
                                    </div>
                                    <div className="team-name text-center py-3">
                                        <h4 className="">Chirag</h4>
                                        <p style={styles.projectText} className="m-0">Chirag brings over 20 years of diverse experience in IT Sales and Consulting to IoTFied. His passion for innovation and commitment to delivering exceptional solutions drive growth. His message - “Together, we will explore the limitless potential of IoT to transform businesses and improve lives”.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rounded team-item">
                            <div className="team-content">
                                <div className="team-img-icon">
                                    <div className="team-img rounded-circle">
                                        <img src="img/team-4.jpg" className="img-fluid w-100 rounded-circle" alt="Team Member 4" />
                                    </div>
                                    <div className="team-name text-center py-3">
                                        <h4>Full Name</h4>
                                        <p className="m-0">Designation</p>
                                    </div>
                                    <div className="team-icon d-flex justify-content-center pb-4">
                                        <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-twitter"></i></a>
                                        <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-instagram"></i></a>
                                        <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-nav">
                        <button onClick={prevSlide}>&lt;</button>
                        <button onClick={nextSlide}>&gt;</button>
                    </div>
                </div>
            </div>

            {/* Blog */}
            <div className="blog-section">
                <div className="container-fluid py-5">
                    <div className="container">
                        <div className="text-center mb-5">
                            <h5 className="text-primary">Our Blog</h5>
                            <h1>Latest Blog & News</h1>
                        </div>
                        <div className="row g-4">
                            {posts.slice(0, 3).map((post) => (
                                <div key={post.id} className="col-lg-4 col-md-6">
                                    <div className="blog-item rounded shadow-sm" style={styles.blogItem}>
                                        <div className="blog-content p-4">
                                            <h5 className="blog-title mb-3 text-secondary" style={{ ...styles.blogTitle, height: '160px', overflow: 'hidden' }}>
                                                {he.decode(post.title.rendered)}
                                            </h5>
                                            <p className="blog-date mb-3" style={styles.blogDate}>{formatDate(post.date)}</p>
                                            <p className="blog-excerpt mb-3" style={{ ...styles.blogExcerpt, height: '265px', overflow: 'hidden', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: getFirst400Chars(post.content.rendered) }}></p>
                                            <div className="blog-footer d-flex justify-content-between align-items-center">
                                                <a href={post.link} target="_blank" className="btn btn-sm text-white bg-secondary" style={styles.readMoreBtn}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact */}
            <div className="container-fluid py-5 mt-5">
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
                        <h5 className="text-primary">Get In Touch</h5>
                        <h1 className="mb-3">Contact for any query</h1>
                        <p className="mb-2">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p>
                    </div>
                    <div className="contact-detail position-relative p-5">
                        <div className="row g-5 mb-5 justify-content-center">
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            {/* <i className="fas fa-map-marker-alt text-white"></i> */}
                                            <FontAwesomeIcon icon={faBuilding} className="text-white" />
                                            {/* <FontAwesomeIcon icon={faDesktop} className="text-white" /> */}
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Design Center</h4>
                                            <p className="m-0"><a href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6" target="_blank" className="text-dark text-decoration-none">708 HareKrishna Complex, Opposite Kothawala Flats, Paldi, Ahmedabad, Gujarat, India 380006</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            {/* <i className="fa fa-industry text-white"></i> */}
                                            <FontAwesomeIcon icon={faChartLine} className="text-white" />
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Development Center</h4>
                                            <p className="m-0"><a href="tel:+0123456789" target="_blank" className="text-dark text-decoration-none">425, Advance Business Park, Shahibaug, Ahmedabad-380004, India</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            <i className="fa fa-industry text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Production Center</h4>
                                            <p className="m-0"><a href="mailto:info@example.com" target="_blank" className="text-dark text-decoration-none">B-11/2, 1st Floor, Electronics Estate, GIDC, Sector-25, Gandhinagar, Gujarat, India-382024</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            <i className="fa fa-phone text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Call Us</h4>
                                            <p className="m-0"><a href="tel:+918320116640" target="_blank" className="text-dark text-decoration-none">+918320116640</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                                <div className="card bg-light rounded-3 border-0 shadow-sm h-100">
                                    <div className="card-body d-flex align-items-center">
                                        <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{ width: '64px', height: '64px' }}>
                                            <i className="fa fa-envelope text-white"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="text-primary mb-0">Email Us</h4>
                                            <p className="m-0"><a href="mailto:sales@iotfied.com" target="_blank" className="text-dark text-decoration-none">sales@iotfied.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                                <div className="p-5 h-100 rounded contact-map">
                                    <iframe
                                        className="rounded w-100 h-100"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4015973662617!2d72.58664647477084!3d23.045733865464555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1sen!2sin!4v1720012449460!5m2!1sen!2sin"
                                        style={{ border: '0' }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                                <div className="p-5 rounded contact-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <input type="text" className="form-control border-0 py-3" placeholder="Your Name" name="from_name" onChange={handleChange} value={formData.from_name} />
                                            {errors.from_name && <p className="text-danger">{errors.from_name}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <input type="email" className="form-control border-0 py-3" placeholder="Your Email" name="email" onChange={handleChange} value={formData.email} />
                                            {errors.email && <p className="text-danger">{errors.email}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <input type="text" className="form-control border-0 py-3" placeholder="Project" name="project" onChange={handleChange} value={formData.project} />
                                            {errors.project && <p className="text-danger">{errors.project}</p>}
                                        </div>
                                        <div className="mb-4">
                                            <textarea className="w-100 form-control border-0 py-3" rows="3" cols="5" placeholder="Message" name="message" onChange={handleChange} value={formData.message}></textarea>
                                            {errors.message && <p className="text-danger">{errors.message}</p>}
                                        </div>
                                        <div className="mb-4 d-flex align-items-center" style={styles.captchaContainer}>
                                            <div className="form-control border-0" style={styles.captchaCode}>{captchaCode}</div>
                                            <input type="text" className="form-control border-0 py-3" style={styles.captchaInput} placeholder="Enter Captcha" name="captcha" onChange={handleChange} value={formData.captcha} />
                                            <button className="btn bg-primary text-white py-3" style={styles.captchaButton} onClick={handleRefreshCaptcha}>Refresh</button>
                                        </div>
                                        {errors.captcha && <p className="text-danger">{errors.captcha}</p>}
                                        <div className="text-start mt-3">
                                            <button className="btn bg-primary text-white py-3 px-5" type="submit">Send Message</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* scrollToTop Button */}
            <div className="scroll-to-top">
                {isVisible && (
                    <div onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faArrowUp} className="scroll-to-top-icon" />
                    </div>
                )}
            </div>
        </>
    )
}


const styles = {
    projectText: {
        textAlign: 'justify',
    },
    sectionH3: {
        padding: '10px 25px',
        color: '#f55b14',
        fontSize: '24px',
        fontWeight: 'bold',
        borderBottom: '2px solid #f55b14',
    },
    textLable: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        backgroundColor: '#f55b14',
        color: 'white',
        padding: '11px 22px',
        width: '30%',
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 50%, 80% 100%, 20% 100%, 0% 50%)',
        margin: '0 auto',
        transition: 'color 0.3s ease',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    titleDividerLine: {
        position: 'absolute',
        top: '43%',
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: '#f55b14',
        zIndex: 0,
        transform: 'translateY(-50%)',
    },
    projectBGimg: {
        backgroundImage: `url(${textBackgoundImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.3,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
    },
    dividerCube: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        width: '25px',
        height: '25px',
        backgroundColor: '#333333',
        clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
        margin: '0 auto',
        transition: 'color 0.3s ease',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    },
    dividerLine: {
        position: 'absolute',
        top: '39%',
        left: '0%',
        right: '0%',
        height: '2px',
        backgroundColor: '#333333',
        zIndex: 0,
        transform: 'translateY(-50%)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    blogItem: {
        border: '1px solid #f55b14',
        backgroundColor: '#edebeb',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        ':hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 15px 30px rgba(0, 0, 0, 0.1)',
        },
    },
    blogTitle: {
        borderBottom: '1px solid #f55b14',
        fontSize: '1.5rem',
        fontWeight: '700',
        color: '#343a40',
    },
    blogDate: {
        fontSize: '0.9rem',
        color: '#f55b14',
    },
    blogExcerpt: {
        fontSize: '1rem',
        color: '#495057',
    },
    readMoreBtn: {
        borderRadius: '10px',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#007bff',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        ':hover': {
            color: '#0056b3',
        },
    },
    blogComment: {
        background: '#007bff',
        color: '#fff',
    },
    blogCommentLink: {
        color: '#fff',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        ':hover': {
            color: '#dfe6e9',
        },
    },
    blogCommentSmall: {
        fontSize: '0.85rem',
    },
    textPrimary: {
        color: '#007bff !important',
    },
    captchaContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    captchaCode: {
        flex: '1 1 100px',
        margin: '5px 0',
        padding: '10px',
        fontSize: '1.5em',
        textAlign: 'center',
        background: '#f8f9fa',
    },
    captchaInput: {
        flex: '2 1 200px',
        margin: '5px',
        padding: '10px',
        fontSize: '1em',
    },
    captchaButton: {
        flex: '1 1 100px',
        padding: '10px',
        fontSize: '1em',
        whiteSpace: 'nowrap',
    },
};